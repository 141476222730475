<template>
  <div class="page" id="reorganize">
    <el-row :gutter="10">
      <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
        <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
             @click="showList = !showList"></div>
        <div class="bg-white leftHeighe" v-show="showList">
          <el-descriptions title="藏品分类"></el-descriptions>
          <div class="flex_b_c">
            <el-input size="small" placeholder="输入关键字进行过滤" clearable v-model="filterText"
                      @input="filterTreeData"></el-input>
          </div>
          <el-tree v-loading="treeLoading" node-key="id" ref="tree" :data="treeData" class="treeDateCls"
                   show-checkbox :filter-node-method="filterNode"
                   :props="defaultProps" @check="selRecord">
            <div slot-scope="{ node, data }">
              <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                <div class="omit">{{ node.label }}</div>
              </el-tooltip>
            </div>
          </el-tree>
        </div>
      </el-col>
      <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
        <div class="text_center noData itembaf" v-if="!archivesBasicDataId.length">请选择左侧藏品分类</div>
        <div v-else>
          <div>
            <div class="flex_b_c pageAllDateNum bg-white">
              <div class="itemCol flex_b_c">
                <div>
                  <div class="num">{{ allTotal }}</div>
                  <div class="itemCol-name">总数</div>
                </div>
                <div class="iconfont icon-zongshu itemCol-icon"></div>
              </div>
              <div class="itemCol itemCol2 flex_b_c">
                <div>
                  <div class="num">{{ rejectagreeCount }}</div>
                  <div class="itemCol-name">审核驳回</div>
                </div>
                <div class="iconfont icon-bohui itemCol-icon"></div>
              </div>
              <div class="itemCol itemCol3 flex_b_c">
                <div>
                  <div class="num">{{ draftCount }}</div>
                  <div class="itemCol-name">未提交</div>
                </div>
                <div class="iconfont icon-weitijiao itemCol-icon"></div>
              </div>
              <div class="itemCol itemCol4 flex_b_c">
                <div>
                  <div class="num">{{ underReviewCount }}</div>
                  <div class="itemCol-name">审核中</div>
                </div>
                <div class="iconfont icon-shenhezhong itemCol-icon"></div>
              </div>
            </div>

            <div class="bg-white tabsCls2">
              <el-form class="query-form dynamicForm" size="small" ref="inputForm"
                       :model="inputForm"
                       @keyup.enter.native="getDataList(1,1,1)"
                       :rules="rules" label-width="150px">
                <el-form-item label="藏品名称" prop="collectionName">
                  <el-input v-model.trim="inputForm.collectionName"
                            placeholder="请输入藏品名称(限50字)"
                            maxlength="50" clearable></el-input>
                </el-form-item>
                <el-form-item label="藏品编号" prop="helpNum">
                  <el-input v-model.trim="inputForm.helpNum"
                            placeholder="请输入藏品编号(限50字)"
                            maxlength="50"
                            clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="完残程度" prop="integrity">
                  <el-select v-model="inputForm.integrity"
                             placeholder="请选择完残程度"
                             style="width: 100%"
                             clearable>
                    <el-option
                        v-for="item in integrityList"
                        :key="item.id"
                        :label="item.levelName"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="完残情况" prop="integrityInfo">
                  <el-input v-model="inputForm.integrityInfo"
                            placeholder="请输入完残情况(限50字)"
                            maxlength="50" clearable></el-input>
                </el-form-item>

                <el-form-item :prop="item.code"
                              label-width="0"
                              v-for="(item, index) in config"
                              :key="index" v-if="item.dataWhere == 0">
                  <div class="flex_b_c metadata">
                    <el-tooltip :disabled="item.overflow" class="item" effect="dark" :content="item.basicName"
                                placement="top">
                      <div class="omit text_right metadataName" ref="configName">{{ item.basicName }}</div>
                    </el-tooltip>
                    <div class="metadataCont">
                      <!--字符-->
                      <el-input v-model.trim="inputForm[item.code]" :maxlength="item.maxLength"
                                :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                                clearable
                                v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
                      <!--数值、浮点数-->
                      <el-input v-model.trim="inputForm[item.code]"
                                :maxlength="item.archivesBasicTypeCode == 'integer'? '8':'11'"
                                @input="getNumType(item)"
                                :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                                clearable
                                v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
                      <el-select class="w100i" v-model="inputForm[item.code]"
                                 :multiple="item.archivesBasicMultiselectList[0].selectBy == 1"
                                 collapse-tags
                                 :placeholder="item.forName ? '请选择' + item.forName : '请选择' + item.basicName"
                                 clearable
                                 v-if="item.archivesBasicMultiselectList.length">
                        <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                                   :label="v.archivesBasicValue"
                                   :value="v.archivesBasicValue"></el-option>
                      </el-select>
                      <!--时间-->
                      <el-date-picker class="w100i"
                                      v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                      format="yyyy-MM-dd HH:mm:ss"
                                      type="datetime" clearable placeholder="请选择"
                                      v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss'">
                      </el-date-picker>
                      <el-date-picker class="w100i"
                                      v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                      format="yyyy-MM-dd"
                                      type="date" clearable placeholder="请选择"
                                      v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
                      </el-date-picker>
                      <el-date-picker class="w100i"
                                      v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                      format="yyyyMMdd"
                                      type="date" clearable placeholder="请选择"
                                      v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd'">
                      </el-date-picker>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
              <div class="flex_b_c" style="margin-top: 10px">
                <div>
                  <el-button size="small" type="primary" @click="getDataList(1,1,1)"
                             icon="el-icon-search">
                    查询
                  </el-button>
                  <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置
                  </el-button>
                </div>
                <div>
                  <el-button type="primary"
                             size="small"
                             v-if="selectTab == 0 || selectTab == 3"
                             v-show="hasPermissionButton('collection:accounts:registration:index:add')"
                             icon='el-icon-plus'
                             @click="addData(0)">新增
                  </el-button>
                  <el-button type="primary" icon="el-icon-search" size="small"
                             @click="advancedSearch()">高级检索
                  </el-button>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white magTop">
            <div class="flex_b_c">
              <div class="tabsCls">
                <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
                  <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                               :name="item.value"></el-tab-pane>
                </el-tabs>
              </div>
              <div>
                <el-button size="small"
                           v-show="hasPermissionButton('collection:accounts:registration:index:batchExpor')"
                           @click="exportData(1)">
                  <i class="icon-piliangdaochu iconfont buIcon"/>
                  批量导出
                </el-button>
                <el-button icon="el-icon-upload2" size="small" v-if="selectTab == 0 || selectTab == 3"
                           v-show="hasPermissionButton('collection:accounts:registration:index:import')"
                           @click="importDile()">
                  批量导入
                </el-button>
                <el-button icon="el-icon-position" size="small" v-if="selectTab == 3"
                           v-show="hasPermissionButton('collection:accounts:registration:index:batchSubmit')"
                           @click="submitTo(1)">
                  批量提交
                </el-button>
                <el-button icon="el-icon-download" size="small" v-if="selectTab == 0 || selectTab == 3"
                           v-show="hasPermissionButton('collection:accounts:registration:index:template')"
                           @click="templateDownload()">
                  导入模板下载
                </el-button>
                <el-button icon="el-icon-cpu" size="small" v-if="selectTab == 2 || selectTab == 3"
                           v-show="hasPermissionButton('collection:accounts:registration:index:intelligent')"
                           @click="intelligent()">
                  智能处理
                </el-button>
              </div>
            </div>
            <el-table
                :data="dataList"
                size="small"
                v-loading="loading"
                ref="multipleTable"
                height="calc(100vh - 350px)"
                class="table tableOiafiuh"
                :row-key="'id'"
                @sort-change="sortChange"
                @selection-change="selectionChangeHandle"
            >
              <el-table-column :reserve-selection="true" type="selection" width="50"/>
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="processState" label="审核状态" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ $dictUtils.getDictLabel("process_state", scope.row.processState, '-') }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="图标" width="120px">
                <template slot-scope="scope">
                  <div class="flex_a_c">
                    <div v-if="scope.row.carded == 0"
                         class="icon-wenwukapian iconfont listIcon"></div>
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="scope.row.picMasterViewUrl"
                        :fit="'cover'"
                        :preview-src-list="[scope.row.picMasterViewUrl]"
                    >
                      <div slot="error" class="image-slot">
                        <el-image
                            style="width: 36px; height: 36px"
                            :src="require('@/assets/img/default.png')"
                            :fit="'cover'">
                        </el-image>
                      </div>
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="collectionName" label="藏品名称"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="helpNum" sortable min-width="120px" label="辅助账编号"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="generalNum" sortable min-width="120px" label="总账编号"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="integrityInfo" label="完残情况"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
              <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
                </template>
              </el-table-column>
              <el-table-column v-for="(item, index) in config" :key="item.archivesBasicId"
                               :prop="item.code"
                               :sortable="item.archivesBasicTypeCode == 'date' || item.archivesBasicTypeCode == 'integer' ? 'custom' : false"
                               :label="item.basicName" v-if="item.dataSelect == 0" min-width="130"
                               show-overflow-tooltip>
                <template slot="header" slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName"
                              placement="top-start">
                    <span class="omit">{{ item.forName ? item.forName : item.basicName }}</span>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <span
                      v-if="item.archivesBasicTypeCode == 'date'">{{
                      metadataDateFormatValue(item, scope.row.archivesData[item.code])
                    }}</span>
                  <span v-else>{{ scope.row.archivesData[item.code] }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="createUserName" label="创建者"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="createDate" sortable label="创建时间" width="150px">
                <template slot-scope="scope">
                  {{ scope.row.createDate | formatDate }}
                </template>
              </el-table-column>
              <el-table-column prop="updateUserName" label="最后更新人"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.updateDate | formatDate }}
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="180">
                <template slot-scope="scope">
                  <el-button size="mini" type="text"
                             @click="addData(1, scope.row,scope.$index)"
                             v-show="hasPermissionButton('collection:accounts:registration:index:detail')">
                    详情
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="submitTo(2,scope.row)"
                             v-if="(selectTab === '0' && scope.row.processState == 0) || (selectTab === '3' && scope.row.processState == 0)"
                             v-show="hasPermissionButton('collection:accounts:registration:index:submit')">
                    提交
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="submitTo(4,scope.row,scope.$index)"
                             v-if="(selectTab === '0' && scope.row.processState == 2) || (selectTab === '2' && scope.row.processState == 2)"
                             v-show="hasPermissionButton('collection:accounts:registration:index:resubmit')">
                    重新提交
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="addData(2, scope.row,scope.$index)"
                             v-if="scope.row.processState !=  1 && scope.row.processState !=  3"
                             v-show="hasPermissionButton('collection:accounts:registration:index:edit')">
                    修改
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="record(scope.row)"
                             v-show="hasPermissionButton('collection:accounts:registration:index:record')">
                    审核记录
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="deleteDate(scope.row)"
                             v-if="scope.row.processState == 0 || scope.row.processState == 2"
                             v-show="hasPermissionButton('collection:accounts:registration:index:delete')">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="text_center">
              <el-pagination
                  @size-change="sizeChangeHandle"
                  @current-change="currentChangeHandle"
                  :current-page="pageNo"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  :total="total"
                  background
                  layout="total, sizes, prev, pager, next, jumper"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <record ref="record"></record>
    <temDownload ref="temDownload"></temDownload>
    <ExportData ref="exportData" @downLoad="getDataList('',1);getallNum()"></ExportData>
    <ImportColl ref="importColl" @importFile="getDataList(1,1,1);getallNum()"></ImportColl>
    <AdvancedSearch ref="advancedSearch" @getDataList="getSrhCondition"></AdvancedSearch>
    <intelligent-coll ref="intelligentColl" @refreshData="getDataList('',1)"></intelligent-coll>
  </div>
</template>

<script>
import {hasPermissionButton} from "@/utils";
import record from "@/views/modules/collection/accounts/module/record.vue";
import temDownload from "@/views/modules/collection/accounts/module/temDownload.vue";
import ExportData from "@/views/modules/record/collect/exportData.vue";
import ImportColl from "@/views/modules/collection/accounts/module/importColl.vue";
import AdvancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import IntelligentColl from "@/views/modules/collection/accounts/module/intelligentColl.vue";

export default {
  name: "index.vue",
  components: {IntelligentColl, AdvancedSearch, record, temDownload, ExportData, ImportColl},
  data() {
    return {
      treeData: [],
      treeLoading: false,
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'archivesBasicName'
      },
      showList: true,

      selectTab: 0,
      tabsList: [
        {
          name: '全部',
          value: '',
        },
        {
          name: '审核中',
          value: '1',
        },
        {
          name: '审核驳回',
          value: '2',
        },
        {
          name: '草稿',
          value: '3',
        },
      ],
      config: [],
      inputForm: {},
      inputFormCopy: '',
      rules: {},
      archivesBasicDataId: [],
      loading: false,
      dataList: [],

      dataListSelect: [],
      pageNo: 1,
      pageNo2: 0,
      pageSize: 10,
      total: 0,
      allTotal: 0,
      rejectagreeCount: 0,
      underReviewCount: 0,
      draftCount: 0,
      advancedList: [],
      searchForm: {
        sortArchivesDataElasticsearch: [],
        whereList: [],
      },
      retract: true,
      searchRecord: {},
      integrityList: [
        {
          id: '残缺',
          levelName: '残缺',
        },
        {
          id: '基本完整',
          levelName: '基本完整',
        },
        {
          id: '完整',
          levelName: '完整',
        },
        {
          id: '严重残缺',
          levelName: '严重残缺',
        },
      ],
    }
  },

  mounted() {
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      this.selectTab = listSearch.selectTab
      this.archivesBasicDataId = listSearch.archivesBasicDataId
      this.advancedList = listSearch.advancedList
      this.inputFormCopy = listSearch.inputForm
      this.searchForm.whereList = listSearch.advancedList
      this.pageNo = listSearch.pageNo
      this.pageNo2 = listSearch.pageNo
      this.pageSize = listSearch.pageSize
      this.searchTree(1)
      sessionStorage.removeItem('listSearch')
      console.log(this.archivesBasicDataId)
    } else {
      this.searchTree()
    }
    this.getallNum()
  },

  methods: {
    hasPermissionButton,

    // 查询树状数据
    searchTree(type) {
      this.treeData = [
        {
          id: '',
          archivesBasicName: '全部',
          children: [],
        },
      ]
      this.treeLoading = true
      this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
        if (res.status) {
          this.treeData[0].children = res.data
          //全选
          this.$nextTick(() => {
            if (!type) {
              this.getTreeId(this.treeData)
            }
            this.$refs.tree.setCheckedKeys(this.archivesBasicDataId);
            this.selRecord('', '', type)
          })
        }
        this.treeLoading = false
      })
    }
    ,

    //循环取树形控件id
    getTreeId(data) {
      if (data && data.length != 0) {
        data.forEach(item => {
          this.archivesBasicDataId.push(item.id)
          if (item.children) {
            this.getTreeId(item.children)
          }
        })
      } else {
        return
      }
    },

    // 获取元数据类型设置输入正则
    getNumType(item) {
      let regular = /[^\d]/g
      if (item.archivesBasicTypeCode == "double") {
        regular = /[^\d.]/g
      }
      this.$set(this.inputForm, item.code, this.inputForm[item.code].replace(regular, ''))
    },

    // 选择档案分类
    selRecord(data, checked, type) {
      //该节点所对应的对象、树目前的选中状态对象
      this.searchForm.sortArchivesDataElasticsearch = []
      if (checked) {
        this.archivesBasicDataId = []
        this.archivesBasicDataId = checked.checkedNodes.map(item => {
          return item.id
        })
      }
      if (this.archivesBasicDataId.length == 0) return
      let getByIdUrl = this.api.collection.basicdataGetById + '/' + this.archivesBasicDataId
      if (this.archivesBasicDataId.length > 1) {
        getByIdUrl = this.api.collection.getArchivesBasicByRequired
      }
      this.$axios(getByIdUrl).then(data => {
        if (data.status) {
          if (!type) {
            this.inputForm = {
              helpNum: '',
              collectionName: '',
              integrity: '',
              integrityInfo: '',
            }
          }
          this.rules = {}
          this.config = data.data.archivesBasicDataGroup
          this.config.map(item => {
            if (item.dataWhere == 0) {
              this.$set(item, 'value', '')
              this.$set(this.inputForm, item.code, '')
              if (item.archivesBasicTypeCode == 'integer') {
                this.$set(this.rules, item.code, [{
                  validator: this.validator.isDigits,
                  trigger: 'blur'
                }])
              }
            }
          })
          if (this.inputFormCopy) {
            // Object.keys(this.inputForm).forEach(key => {
            //   this.inputForm[key] = this.inputFormCopy[key]
            // })
            this.inputForm = this.inputFormCopy
            this.inputFormCopy = ''
          }
          this.$nextTick(() => {
            this.textExceeds(this.config, 'configName')
          })
          this.getDataList(type ? '' : 1, 1, 1)
        }
      })
    },

    //判断文字是否超出范围
    textExceeds(list, refName) {
      let configName = this.$refs[refName]
      if (!list || !list.length) return
      list.forEach((item, index) => {
        if (configName[index].scrollWidth > configName[index].clientWidth) {
          this.$set(item, 'overflow', false)
        } else {
          this.$set(item, 'overflow', true)
        }
      })
    },

    //获取列表数据
    getDataList(type, dividePage, archivesData) {
      this.$refs['inputForm'].validate((valid) => {
        if (valid) {
          if (type == 1) {
            this.pageNo = 1
          }
          if (dividePage == 1) {
            this.$refs.multipleTable.clearSelection()
          }
          if (archivesData == 1) {
            this.searchForm.sortArchivesDataElasticsearch = []
            this.$refs.multipleTable.clearSort()
          }
          this.loading = true
          let keys = Object.keys(this.inputForm)
          let values = Object.values(this.inputForm)
          let searchForm = JSON.parse(JSON.stringify(this.searchForm))
          keys.map((key, index) => {
            this.config.map(item => {
              if (item.code == key && values[index] != '' && values[index] != null) {
                if (item.archivesBasicTypeCode == 'text') {
                  // 多选遍历多选的值
                  if (Array.isArray(values[index])) {
                    values[index].map(v => {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: v
                      })
                    })
                  } else {
                    if (item.archivesBasicMultiselectList.length) {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "=",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: values[index]
                      })
                    } else {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: values[index]
                      })
                    }
                  }
                }
                if (item.archivesBasicTypeCode == 'integer') {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                }
                if (item.archivesBasicTypeCode == 'date') {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: ">=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "<=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                }
                if (item.archivesBasicTypeCode == "double") {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                }
              }
            })
          })
          let fieldList = [
            {
              archivesBasicTypeMethodCode: "=",
              key: "entryState",
              theKey: "",
              value: "0"
            },
          ]
          let fieldOrList = []
          if (this.inputForm.collectionName) {
            fieldList.push(
                {
                  archivesBasicTypeMethodCode: "%",
                  key: 'collectionName',
                  archivesBasicTypeCode: 'text',
                  value: this.inputForm.collectionName
                },
            )
          }
          let fieldWhereList = []
          if (this.inputForm.integrity) {
            fieldWhereList.push(
                {
                  archivesBasicTypeMethodCode: "=",
                  archivesBasicTypeCode: 'text',
                  key: 'integrity',
                  value: this.inputForm.integrity
                },
            )
          }
          if (this.inputForm.integrityInfo) {
            fieldWhereList.push(
                {
                  archivesBasicTypeMethodCode: "=",
                  archivesBasicTypeCode: 'text',
                  key: 'integrityInfo',
                  value: this.inputForm.integrityInfo
                },
            )
          }
          let detailTyupe
          if (this.selectTab != 0) {
            if (this.selectTab == 1) {
              detailTyupe = 1;
            } else if (this.selectTab == 2) {
              detailTyupe = 2;
            } else if (this.selectTab == 3) {
              detailTyupe = 0;
            }
            fieldList.push(
                {
                  archivesBasicTypeMethodCode: "=",
                  key: "processState",
                  theKey: "",
                  //审核状态0 草稿，1 审核中，2 驳回，3 审核通过
                  value: detailTyupe,
                },
            )

          }
          let archivesBasicDataId = this.archivesBasicDataId.filter(item => {
            return item == ''
          })
          if (archivesBasicDataId.length != 0) {
            archivesBasicDataId = null
          } else {
            archivesBasicDataId = this.archivesBasicDataId
          }
          this.searchRecord = {
            ...searchForm,
            fieldList: fieldList,
            fieldOrList,
            number: this.inputForm.helpNum,
            fieldWhereList,
            current: this.pageNo2 ? this.pageNo2 : this.pageNo,
            size: this.pageSize,
            archivesBasicDataId: archivesBasicDataId,
          }
          this.$axios(this.api.collection.queryElasticsearchTemplatePage, this.searchRecord, 'post').then((res) => {
            if (res.status) {
              this.dataList = res.data.records
              this.$nextTick(() => {
                this.$refs.multipleTable.doLayout()
              })
              this.total = parseInt(res.data.total)
              if (this.dataList.length == 0 && this.pageNo > 1) {
                this.pageNo--
                this.getDataList()
              }
              if (this.pageNo2) {
                this.pageNo = this.pageNo2
                this.pageNo2 = 0
              }
            } else {
              this.$message.error('查询失败');
            }
            this.loading = false
          })
        }
      })
    }
    ,

    //获取总数等
    getallNum() {
      this.$axios(this.api.collection.selectCollectionDataStateCount, {}, 'get').then((res) => {
        if (res.status) {
          this.allTotal = res.data.total
          this.rejectagreeCount = res.data.rejectagreeCount
          this.underReviewCount = res.data.underReviewCount
          this.draftCount = res.data.draftCount
        }
      })
    }
    ,

    //导入
    importDile() {
      this.$refs.importColl.init(this.treeData[0].children, '', '', {type: 1,})
    },

    //筛选树的数据
    filterTreeData() {
      this.$refs.tree.filter(this.filterText);
    },

    // 高级检索
    advancedSearch() {
      this.$refs.advancedSearch.init(this.config, this.advancedList)
    },

    // 获取高级检索条件
    getSrhCondition(data) {
      if (data) {
        this.advancedList = data.advancedList
        this.searchForm.whereList = data.whereList
        this.getDataList(1, 1, 1)
      }
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.archivesBasicName.indexOf(value) !== -1;
    },

    //重置
    resetting() {
      this.$refs.inputForm.resetFields();
      this.searchForm.whereList = []
      this.advancedList = []
      this.getDataList(1, 1, 1)
    },

    //导出 type 1批量导出 2全部导出
    exportData(type) {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = this.dataListSelect.map(item => {
        if (item) {
          return {
            id: item.id,
            archivesBasicDataId: item.archivesBasicDataId
          }
        }
      })
      this.$refs.exportData.init(ids, this.config, this.archivesBasicDataId, '藏品数据', 1)
    },

    //提交 type 1批量提交 2单个提交
    submitTo(type, row) {
      let ids
      if (type == 1) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        ids = this.dataListSelect.map(item => {
          if (item) {
            return item.id
          }
        })
      } else {
        ids = [row.id]
      }
      this.$confirm(`您是否确认提交数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.bacthSubmit, {
          ids: ids,
          revdId: 0,
        }, 'post').then(data => {
          if (data && data.status) {
            this.$message.success('提交成功')
            this.getallNum()
            this.getDataList('', 1)
          }
        })
      })
    }
    ,

    //导入模板下载
    templateDownload() {
      // this.$refs.temDownload.init(this.treeData[0].children)
      this.$axios(this.api.collection.exportModel, {}, 'get', 'blob').then(res => {
        const blob = new Blob([res.data], {
          // 下载的文件格式自己在这边更改        type的值就好了
          type: 'application/vnd.ms-excel'
        })
        let filename = '藏品登记导入模板';
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        document.body.appendChild(link);
        link.click()
        URL.revokeObjectURL(link.href);  //释放url
        document.body.removeChild(link);  //释放标签
      })
    },

    //智能处理
    intelligent() {
      if (!this.dataListSelect.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      this.$refs.intelligentColl.init(this.config, this.dataListSelect)
    },

    // 0新增 1详情 2修改 4重新提交
    addData(num, row, index) {
      if (num != 0) {
        let listSearch = {
          selectTab: this.selectTab,
          archivesBasicDataId: this.archivesBasicDataId,
          advancedList: this.advancedList,
          inputForm: this.inputForm,
          pageNo: this.pageNo,
          pageSize: this.pageSize
        }
        sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
        this.$set(this.searchRecord, 'viewCurrent', (this.pageNo - 1) * this.pageSize + index + 1)
        sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
      }
      if (num == 0) {
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {butType: num},
        })
      }
      if (num == 1) {
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {butType: num, id: row.id, archivesBasicDataId: row.archivesBasicDataId, typePage: 'index'}
        })
      }
      if (num == 2 || num == 4) {
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {butType: num, id: row.id, archivesBasicDataId: row.archivesBasicDataId}
        })
      }
    },

    //审核记录
    record(row) {
      this.$refs.record.init(row.id, '', 0)
    },

    //删除数据
    deleteDate(row) {
      this.$confirm(`您是否确认删除数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.removeById, {
          id: row.id,
          archivesBasicDataId: row.archivesBasicDataId,
        }, 'post').then(data => {
          if (data && data.status) {
            this.$message.success('删除成功')
            this.getDataList('', 1)
            this.getallNum()
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    // 列表排序
    sortChange(column) {
      if (column.prop == 'createDate' || column.prop == 'updateDate') {
        this.searchForm.sortArchivesDataElasticsearch = [
          {
            archivesBasicTypeCode: 'date',
            isSys: 1,
            key: column.prop,
            sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
          }
        ]
      } else if (column.prop == 'helpNum' || column.prop == 'generalNum') {
        this.searchForm.sortArchivesDataElasticsearch = [
          {
            archivesBasicTypeCode: 'text',
            isSys: 1,
            key: column.prop,
            sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
          }
        ]
      } else {
        let sortItem = this.config.filter(item => {
          return item.code == column.prop
        })
        this.searchForm.sortArchivesDataElasticsearch = [
          {
            archivesBasicTypeCode: sortItem[0].archivesBasicTypeCode,
            isSys: 0,
            key: 'archivesData.' + column.prop,
            sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
          }
        ]
      }
      this.getDataList('', 1)
    },

    handleClick(tab, event) {
      this.getDataList(1, 1, 1)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList('', 2);
    },
  },
}
</script>

<style scoped>
.itembaf {
  background: #FFFFFF !important;
  padding: 18px !important;
}

.leftHeighe {
  height: calc(100vh - 124px);
  overflow-y: auto;
}

.magTop {
  margin-top: 10px;
}

.tabsCls2 {
  margin: 10px 0 0 0;
}

.noData {
  line-height: calc(100vh - 154px);
}

/*解决表格fixed布局时横向滚动条遮盖的问题*/
.tableOiafiuh {
  .el-table__fixed-right, .el-table__fixed {
    height: auto !important;
    bottom: 17px !important;
  }
}

.showListBtn {
  left: 0px;
  right: auto;
}

.treeDateCls {
  overflow-y: auto !important;
  height: calc(100% - 80px);
}
</style>
